// COLORS
$white: #ffffff;
$grey: #848484;
$azure: #29BDFF;
$black: #111930;
$jeans: #0E1C37;

// FONT WEIGHT
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;

// FONT SIZES
$heading: 1.5rem;


// MAPS, LISTS
$colors: (
        'white': $white,
        'grey': $grey,
        'azure': $azure,
        'black': $black,
        'jeans': $jeans
);

$font-weights: (
        medium: $medium,
        semibold: $semi-bold,
        bold: $bold,
        extrabold: $extra-bold
);

$font-families: (
        proxima: proxima-nova,
        poppins: 'Poppins'
);

$font-styles: normal, italic;
