#main {
  user-select: none;
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.6) 40%, rgba(0, 0, 0, 0.4) 100%), url("../img/main_back.jpeg") no-repeat center;
  background-size: cover;
  nav {
    @include responsive-typography(1rem, 1rem, 1.3rem, 1.3rem, 1.3rem);
    img {
      @include responsive-logo(6rem);
    }
  }
  .flex-fill {
    .display-3 {
      @include responsive-typography(1.3rem, 2rem, 2.5rem, 3rem, 3.5rem);
      .text-uppercase.azure {
        font-size: 130%;
      }
    }
  }
  .scroll-down {
    .fa-angle-down {
      animation: scroll-down 2.5s infinite ease-in-out;
    }
    cursor: pointer;
  }
  .languages .active {
    @extend .white;
  }
}
