@mixin font($font, $weight, $style: normal) {
  font-family: $font, sans-serif;
  font-weight: $weight;
  font-style: $style;
}

@mixin responsive-typography($xs, $sm: $xs + 0.1rem, $md: $sm + 0.1rem, $lg: $md + 0.2rem, $xl: $lg + 0.2rem) {
  font-size: $xs;
  @include media-breakpoint-up(sm) {
    font-size: $sm;
  }
  @include media-breakpoint-up(md) {
    font-size: $md;
  }
  @include media-breakpoint-up(lg) {
    font-size: $lg;
  }
  @include media-breakpoint-up(xl) {
    font-size: $xl;
  }
}

@mixin responsive-logo($md, $lg: $md + 0.5rem, $xl: $lg) {
  height: auto;
  width: $md;
  @include media-breakpoint-up(lg) {
    width: $lg;
  }
  @include media-breakpoint-up(xl) {
    width: $xl;
  }
}
