#work {
  background: url("../img/work_back.png") no-repeat center;
  background-size: cover;
  & > div.d-flex {
    .btn {
      padding: $spacer $spacer * 6;
      @extend .white;
      &:hover {
        @extend .bg-white, .black;
      }
    }
  }
}
