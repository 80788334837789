@each $family, $font-family in $font-families {
  @each $weight, $font-weight in $font-weights {
    @each $style in $font-styles {
      .#{$family}-#{$weight}-#{$style} {
        @include font($font-family, $font-weight, $style);
      }
    }
  }
}

@each $color, $color-value in $colors {
  .#{$color} {
    color: $color-value;
  }
  .bg-#{$color} {
    background-color: $color-value;
  }
}

@each $direction in top, bottom {
  @each $spacing in padding, margin {
    .header-#{$spacing}-#{$direction} {
      #{$spacing}-#{$direction}: $spacer * 2.5;
    }
    .custom-#{$spacing}-#{$direction} {
      &:not(.#{str_slice($spacing, 1, 1)}#{str_slice($direction, 1, 1)}-0) {
        @extend .#{str_slice($spacing, 1, 1)}#{str_slice($direction, 1, 1)}-5;
      }
      @include media-breakpoint-up(md) {
        #{$spacing}-#{$direction}: $spacer * 5;
      }
    }
    .custom-#{$spacing} {
      @extend .custom-#{$spacing}-#{$direction};
    }
  }
}

* {
  outline-color: $white !important;
  &::selection {
    @extend .bg-azure, .white;
  }
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @extend .poppins-medium-normal;
}

main {
  flex: 1 0 auto;
}

.text-smallcaps {
  font-variant: small-caps;
}

p {
  @extend .poppins-medium-normal, .grey;
  line-height: 2rem;
  @include media-breakpoint-up(md) {
    line-height: 2.5rem;
  }
}

h2 {
  @extend .proxima-extrabold-normal, .header-margin-bottom;
}

.btn-primary {
  @extend .bg-azure;
}

a[href]:not(.no-hover) {
  &:hover {
    @extend .azure;
  }
  //&:focus {
  //  @extend .white;
  //}
}

.fa-bars, .fa-times {
  width: 20px !important;
  height: 20px;
}
