#projects {

  .carousel {

    .carousel-control-next, .carousel-control-prev {
      z-index: 9999;
    }

    .carousel-indicators {
      li {
        width: 22px;
        height: 22px;
        background-color: transparent;
        background-image: url("../img/indicator.svg");

        &.active {
          background-image: url("../img/indicator_active.svg");
        }
      }
    }

    .carousel-item {
      background-size: cover !important;
      &::before {
        background-color: rgba(0,0,0,0.4);
        content: '';
        display: block;
        position: absolute;
        top:0;
        bottom: 0;
        right: 0;
        left:0;
      }

      .carousel-caption {
        left: unset;
        right: unset;
        bottom: unset;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

